import Modal from 'antd/lib/modal/Modal'
import { useState } from 'react'
import { User, UserRole, WarehouseId } from '../../../models/models'
import { createUserRequest } from '../../../api/users'
import { Button, Divider, Input, Select } from 'antd'
import styled from '@emotion/styled'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { isArray } from 'lodash'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface Props {
    isVisible: boolean
    userId: number | null
    onSubmit?: (user: User) => void
    close: () => void
}
export const CreateUserModal = ({ isVisible, close, onSubmit, userId }: Props) => {
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string>('')
    const [phoneNumber, setPhoneNumber] = useState<string>('')
    const [role, setRole] = useState<UserRole>()
    const [warehouseId, setWarehouseId] = useState<WarehouseId>(WarehouseId.Monument)
    const [sageUsername, setSageUsername] = useState<string>('')

    const onClose = () => {
        close()
    }

    const submit = async () => {
        const response = await createUserRequest({
            firstName,
            lastName,
            email,
            phoneNumber,
            role,
            warehouseId,
            sageUsername,
        })
        if (!response.successful) {
            isArray(response.message)
                ? toastFailure(
                      response.message.reduce((acc, cur) => {
                          return acc + `${cur}.` + '\n'
                      }, '')
                  )
                : toastFailure(response.message)
            return
        }
        if (response.successful) {
            if (onSubmit) {
                onSubmit(response.data)
            }
            close()
        }
    }

    return (
        <>
            <Modal
                visible={isVisible}
                title={`Creating user`}
                onCancel={onClose}
                onOk={submit}
                footer={
                    <div>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={submit}>Create</Button>
                    </div>
                }
            >
                <Divider />
                {/* create antd fields for state */}
                <div>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>First Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Last Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Phone Number</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={phoneNumber}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Email</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={warehouseId}
                                onChange={(value): void => {
                                    setWarehouseId(value)
                                }}
                                options={[
                                    { label: 'Monument', value: WarehouseId.Monument },
                                    { label: 'Scandia', value: WarehouseId.Scandia },
                                ]}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Role</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={role}
                                onChange={(value): void => {
                                    setRole(value)
                                }}
                                options={[
                                    { label: 'Assembler', value: UserRole.Assembler },
                                    { label: 'Project Manager', value: UserRole.ProjectManager },
                                    { label: 'Sav Admin', value: UserRole.SavAdmin },
                                    { label: 'System Admin', value: UserRole.SystemAdmin },
                                    { label: 'Account Manager', value: UserRole.AccountManager },
                                    {
                                        label: 'Logistics Employee',
                                        value: UserRole.LogisticsEmployee,
                                    },
                                    {
                                        label: 'Application Engineer',
                                        value: UserRole.ApplicationEngineer,
                                    },
                                    { label: 'Scheduler', value: UserRole.Scheduler },
                                ]}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px', display: 'block' }}>
                        <RowElement>
                            <Label>Sage Username</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={sageUsername}
                                onChange={(e) => setSageUsername(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>
                </div>
            </Modal>
        </>
    )
}
