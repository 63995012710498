import Modal from 'antd/lib/modal/Modal'
import { useEffect, useState } from 'react'
import { User, UserRole, WarehouseId } from '../../../models/models'
import { createUserRequest, getUserRequest, updateUserRequest } from '../../../api/users'
import { Button, Divider, Input, Select } from 'antd'
import styled from '@emotion/styled'
import { toastFailure, toastSuccess } from '../../../util/toast'
import { isArray, isNumber } from 'lodash'

const Header = styled('div')`
    gap: 10px;
    align-items: center;
    display: flex;
`

const InputRow = styled('div')`
    margin-bottom: 5px;
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 20px;
    justify-content: space-around;
`

const RowElement = styled('div')`
    width: 50%;
`

const Title = styled('p')`
    font-size: 20px;
    margin: 0;
`

const Label = styled('label')`
    margin-bottom: 3px;
    display: block;
    font-size: 14px;
    font-weight: 500;
`

interface Props {
    isVisible: boolean
    userId: number | null
    onSubmit?: (user: User) => void
    close: () => void
}

enum ModalStae {
    Loading = 'Loading',
    Ready = 'Ready',
}
export const UpdateUserModal = ({ isVisible, close, onSubmit, userId }: Props) => {
    const [modalState, setModalState] = useState<ModalStae>(ModalStae.Ready)
    const [firstName, setFirstName] = useState<string>('')
    const [lastName, setLastName] = useState<string>('')
    const [email, setEmail] = useState<string | null>('')
    const [phoneNumber, setPhoneNumber] = useState<string | null>('')
    const [role, setRole] = useState<UserRole>()
    const [warehouseId, setWarehouseId] = useState<WarehouseId>(WarehouseId.Monument)
    const [user, setUser] = useState<User | null>(null)
    const [scheduledMinutesMonday, setScheduledMinutesMonday] = useState<number>(0)
    const [scheduledMinutesTuesday, setScheduledMinutesTuesday] = useState<number>(0)
    const [scheduledMinutesWednesday, setScheduledMinutesWednesday] = useState<number>(0)
    const [scheduledMinutesThursday, setScheduledMinutesThursday] = useState<number>(0)
    const [scheduledMinutesFriday, setScheduledMinutesFriday] = useState<number>(0)
    const [sageUsername, setSageUsername] = useState<string | null>('')

    useEffect(() => {
        if (userId) {
            setModalState(ModalStae.Loading)
            const fetchUser = async () => {
                const response = await getUserRequest(userId)
                if (!response.successful) {
                    toastFailure(response.message)
                    return
                }
                if (response.successful) {
                    const user = response.data
                    setUser(user)
                    setFirstName(user.firstName)
                    setLastName(user.lastName)
                    setEmail(user.email)
                    setPhoneNumber(user.phoneNumber)
                    setRole(user.role)
                    setWarehouseId(user.warehouseId)
                    setScheduledMinutesMonday(user.scheduledMinutesMonday)
                    setScheduledMinutesTuesday(user.scheduledMinutesTuesday)
                    setScheduledMinutesWednesday(user.scheduledMinutesWednesday)
                    setScheduledMinutesThursday(user.scheduledMinutesThursday)
                    setScheduledMinutesFriday(user.scheduledMinutesFriday)
                    setModalState(ModalStae.Ready)
                    setSageUsername(user.sageUsername)
                }
            }
            fetchUser()
        }
    }, [userId, isVisible])

    const onClose = () => {
        close()
    }

    const submit = async () => {
        if (!userId) {
            toastFailure('User not found')
            return
        }
        if (!firstName || !lastName || !email || !role) {
            toastFailure('Please fill in required fields')
            return
        }
        const response = await updateUserRequest(userId, {
            firstName,
            lastName,
            email,
            phoneNumber: phoneNumber ?? undefined,
            role,
            warehouseId,
            scheduledMinutesMonday,
            scheduledMinutesTuesday,
            scheduledMinutesWednesday,
            scheduledMinutesThursday,
            scheduledMinutesFriday,
            sageUsername: sageUsername ?? undefined,
        })
        if (!response.successful) {
            isArray(response.message)
                ? toastFailure(
                      response.message.reduce((acc, cur) => {
                          return acc + `${cur}.` + '\n'
                      }, '')
                  )
                : toastFailure(response.message)
            return
        }
        if (response.successful) {
            toastSuccess(`${response.data.firstName} ${response.data.lastName} Updated`)
            if (onSubmit) {
                onSubmit(response.data)
            }
            close()
        }
    }

    return (
        <>
            <Modal
                visible={isVisible}
                title={`Updating ${user ? `${user.firstName} ${user.lastName}` : 'user'}`}
                onCancel={onClose}
                onOk={submit}
                footer={
                    <div>
                        <Button onClick={onClose}>Cancel</Button>
                        <Button onClick={submit}>Update</Button>
                    </div>
                }
            >
                <Divider />
                {/* create antd fields for state */}
                <div>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>First Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={firstName}
                                onChange={(e) => setFirstName(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Last Name</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={lastName}
                                onChange={(e) => setLastName(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Phone Number</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={phoneNumber ?? ''}
                                onChange={(e) => setPhoneNumber(e.target.value)}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Email</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={email ?? ''}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px' }}>
                        <RowElement>
                            <Label>Warehouse</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={warehouseId}
                                onChange={(value): void => {
                                    setWarehouseId(value)
                                }}
                                options={[
                                    { label: 'Monument', value: WarehouseId.Monument },
                                    { label: 'Scandia', value: WarehouseId.Scandia },
                                ]}
                            />
                        </RowElement>
                        <RowElement>
                            <Label>Role</Label>
                            <Select
                                style={{ width: '100%' }}
                                value={role}
                                onChange={(value): void => {
                                    setRole(value)
                                }}
                                options={[
                                    { label: 'Assembler', value: UserRole.Assembler },
                                    { label: 'Project Manager', value: UserRole.ProjectManager },
                                    { label: 'Sav Admin', value: UserRole.SavAdmin },
                                    { label: 'System Admin', value: UserRole.SystemAdmin },
                                    { label: 'Account Manager', value: UserRole.AccountManager },
                                    {
                                        label: 'Logistics Employee',
                                        value: UserRole.LogisticsEmployee,
                                    },
                                    {
                                        label: 'Application Engineer',
                                        value: UserRole.ApplicationEngineer,
                                    },
                                    { label: 'Scheduler', value: UserRole.Scheduler },
                                ]}
                            />
                        </RowElement>
                    </InputRow>
                    <InputRow style={{ marginBottom: '20px', display: 'block' }}>
                        <RowElement>
                            <Label>Sage Username</Label>
                            <Input
                                style={{ width: '100%' }}
                                value={sageUsername ?? ''}
                                onChange={(e) => setSageUsername(e.target.value)}
                            />
                        </RowElement>
                    </InputRow>
                    {user?.role === UserRole.Assembler && (
                        <>
                            <InputRow>
                                <RowElement>
                                    <Label>Monday</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={scheduledMinutesMonday}
                                        onChange={(e) => {
                                            if (isNaN(Number(e.target.value))) {
                                                return
                                            }
                                            setScheduledMinutesMonday(Number(e.target.value))
                                        }}
                                    />
                                </RowElement>
                                <RowElement>
                                    <Label>Tuesday</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={scheduledMinutesTuesday}
                                        onChange={(e) => {
                                            if (isNaN(Number(e.target.value))) {
                                                return
                                            }
                                            setScheduledMinutesTuesday(Number(e.target.value))
                                        }}
                                    />
                                </RowElement>
                            </InputRow>
                            <InputRow>
                                <RowElement>
                                    <Label>Wednesday</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={scheduledMinutesWednesday}
                                        onChange={(e) => {
                                            if (isNaN(Number(e.target.value))) {
                                                return
                                            }
                                            setScheduledMinutesWednesday(Number(e.target.value))
                                        }}
                                    />
                                </RowElement>
                                <RowElement>
                                    <Label>Thursday</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={scheduledMinutesThursday}
                                        onChange={(e) => {
                                            if (isNaN(Number(e.target.value))) {
                                                return
                                            }
                                            setScheduledMinutesThursday(Number(e.target.value))
                                        }}
                                    />
                                </RowElement>
                            </InputRow>
                            <InputRow>
                                <RowElement>
                                    <Label>Friday</Label>
                                    <Input
                                        style={{ width: '100%' }}
                                        value={scheduledMinutesFriday}
                                        onChange={(e) => {
                                            if (isNaN(Number(e.target.value))) {
                                                return
                                            }
                                            setScheduledMinutesFriday(Number(e.target.value))
                                        }}
                                    />
                                </RowElement>
                            </InputRow>
                        </>
                    )}
                </div>
            </Modal>
        </>
    )
}
